<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <validation-observer v-slot="{ handleSubmit }">
      <v-form
        ref="form"
        lazy-validation
      >
        <v-card class="pr-2 pl-2">
          <div class="pt-3 pb-3 d-flex justify-space-between">
            <div>
              <span class="pl-2 pr-4 pt-2 pb-2 header-job">
                <span class="mdi mdi-chevron-right pr-1" />基本情報
              </span>
            </div>
            <div>
              <span>
                <v-btn
                  v-if="isEditInfo"
                  small
                  fab
                  dark
                  depressed
                  outlined
                  color="primary"
                  @click="isEditInfo = false"
                >
                  <v-icon dark>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-btn
                  v-else
                  small
                  fab
                  dark
                  depressed
                  outlined
                  color="primary"
                  @click="isEditInfo = true"
                >
                  <v-icon dark>
                    mdi-pencil-off
                  </v-icon>
                </v-btn>
              </span>
            </div>
          </div>
          <v-card-text class="pl-10 pr-10">
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col
                      cols="4"
                      sm="4"
                      class="text-button"
                      md="4"
                    >
                      <span class="font-weight-bold">ジョブ名 <span class="red--text">*</span></span>
                    </v-col>
                    <v-col
                      cols="8"
                      sm="8"
                      md="8"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <v-text-field
                          v-model="namejob"
                          dense
                          type="text"
                          single-line
                          outlined
                          :error-messages="errors[0]"
                          :disabled="isEditInfo"
                        />
                      </validation-provider>
                    </v-col>

                    <v-col
                      cols="4"
                      sm="4"
                      class="text-button"
                      md="4"
                    >
                      <span class="font-weight-bold">期間 <span class="red--text">*</span></span>
                    </v-col>
                    <v-col
                      cols="8"
                      sm="8"
                      md="8"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <v-text-field
                          v-model="period"
                          dense
                          type="text"
                          single-line
                          outlined
                          :error-messages="errors[0]"
                          :disabled="isEditInfo"
                        />
                      </validation-provider>
                    </v-col>

                    <v-col
                      cols="4"
                      sm="4"
                      class="text-button"
                      md="4"
                    >
                      <span class="font-weight-bold">一日の人数 <span class="red--text">*</span></span>
                    </v-col>
                    <v-col
                      cols="8"
                      sm="8"
                      md="8"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <v-text-field
                          v-model="numOfWorker"
                          dense
                          type="text"
                          single-line
                          outlined
                          :error-messages="errors[0]"
                          :disabled="isEditInfo"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="6">
                  <v-row>
                    <v-col
                      cols="4"
                      sm="4"
                      class="text-button"
                      md="4"
                    >
                      <span class="font-weight-bold">状態 <span class="red--text">*</span></span>
                    </v-col>
                    <v-col
                      cols="8"
                      sm="8"
                      md="8"
                    >
                      <span>
                        <v-switch
                          v-model="job.isActive"
                          class="custome-switch"
                          :label="getLableJobActive(job.isActive)"
                          :disabled="isEditInfo"
                          @change="handleChangeStatusJob($event)"
                        />
                      </span>
                    </v-col>

                    <v-col
                      cols="4"
                      sm="4"
                      class="text-button"
                      md="4"
                    >
                      <span class="font-weight-bold">出勤曜日 <span class="red--text">*</span></span>
                    </v-col>
                    <v-col
                      cols="8"
                      sm="8"
                      md="8"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <v-text-field
                          id="workDay"
                          v-model="workDay"
                          name="workDay"
                          dense
                          single-line
                          outlined
                          :error-messages="errors[0]"
                          :disabled="isEditInfo"
                        />
                      </validation-provider>
                    </v-col>

                    <v-col
                      cols="4"
                      sm="4"
                      class="text-button"
                      md="4"
                    >
                      <span class="font-weight-bold">条件 <span class="red--text">*</span></span>
                    </v-col>
                    <v-col
                      cols="8"
                      sm="8"
                      md="8"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <v-text-field
                          v-model="description"
                          dense
                          type="text"
                          single-line
                          outlined
                          :error-messages="errors[0]"
                          :disabled="isEditInfo"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
        <v-card class="pr-2 pl-2 mt-4">
          <div class="pt-3 pb-3 d-flex justify-space-between">
            <div>
              <span class="pl-2 pr-4 pt-2 pb-2 header-job">
                <span class="mdi mdi-chevron-right pr-1" />シフト設定
              </span>
            </div>
            <div>
              <span
                class="pr-2"
              >
                <v-btn
                  v-if="!isShowBtnAdd"
                  small
                  fab
                  dark
                  depressed
                  outlined
                  color="primary"
                  @click="isShowBtnAdd = true"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
                <v-btn
                  v-else
                  small
                  fab
                  dark
                  depressed
                  outlined
                  color="primary"
                  @click="isShowBtnAdd = false"
                >
                  <v-icon dark>
                    mdi-minus
                  </v-icon>
                </v-btn>
              </span>
              <span>
                <v-btn
                  v-if="isEditTimeShift"
                  small
                  fab
                  dark
                  depressed
                  outlined
                  color="primary"
                  @click="isEditTimeShift = false"
                >
                  <v-icon dark>
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-btn
                  v-else
                  small
                  fab
                  dark
                  depressed
                  outlined
                  color="primary"
                  @click="isEditTimeShift = true"
                >
                  <v-icon dark>
                    mdi-pencil-off
                  </v-icon>
                </v-btn>
              </span>
            </div>
          </div>
          <v-card-text class="pl-10 pr-10">
            <v-container>
              <v-row>
                <v-col
                  v-for="(shift, shiftIndex) in shiftsShow"
                  :key="shiftIndex"
                  cols="6"
                  sm="6"
                  md="6"
                >
                  <span>
                    <v-row>
                      <v-col
                        cols="1"
                        class="pl-0"
                      >
                        <div class="mt-1 pl-4">
                          <v-btn
                            v-if="!isEditTimeShift"
                            color="secondary"
                            fab
                            outlined
                            x-small
                            dark
                            @click="openDialogDeleteShift(shiftIndex)"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="6">
                        <div class="align-self-center job-time">
                          <div class="text-button text-center custome-shift">
                            <span>{{ shift.shiftStartAt }}</span> ~ <span>{{ shift.shiftEndAt }}</span>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <validation-provider
                          v-slot="{ errors }"
                          ref="planBreakTime"
                          rules="checktime"
                        >
                          <v-text-field
                            v-model="shift.planBreakTime"
                            dense
                            single-line
                            outlined
                            v-mask="'##:##'"
                            :error-messages="errors[0]"
                            placeholder="hh:mm"
                            @change.native="handleCheckBreakTime(shift)"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col cols="3">
                        <v-select
                          v-model="shift.worktimeType"
                          :items="statusSubmit"
                          item-text="text"
                          item-value="value"
                          outlined
                          dense
                          :no-data-text="$t('table.messages.no_data')"
                          :disabled="checkIsDisable(isEditTimeShift, checkWorkTimeType(shift.shiftStartAt, shift.shiftEndAt))"
                        />
                      </v-col>
                    </v-row>
                  </span>
                </v-col>
              </v-row>

              <v-row v-if="isShowBtnAdd">
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                >
                  <v-row>
                    <v-col
                      cols="1"
                      class="pl-0"
                    />
                    <v-col
                      cols="5"
                    >
                      <div class="align-self-center">
                        <validation-provider
                          v-slot="{ errors }"
                          ref="fromtime"
                          name="fromtimeValidation"
                          rules="checktime"
                        >
                          <v-text-field
                            v-model="shiftStartAt"
                            dense
                            single-line
                            outlined
                            :error-messages="errors[0]"
                            placeholder="hh:mm"
                            @blur="handleShift()"
                            @focus="isActiveSave = true"
                          />
                        </validation-provider>
                      </div>
                    </v-col>
                    <v-col
                      cols="1"
                      class="text-button"
                    >
                      ～
                    </v-col>
                    <v-col
                      cols="5"
                    >
                      <div class="align-self-center">
                        <validation-provider
                          v-slot="{ errors }"
                          name="totimeValidation"
                          rules="checktime"
                        >
                          <v-text-field
                            v-model="shiftEndAt"
                            dense
                            single-line
                            outlined
                            placeholder="hh:mm"
                            :error-messages="errors[0]"
                            @blur="handleShift()"
                            @keydown.enter.native="handleShift()"
                            @focus="isActiveSave = true"
                          />
                        </validation-provider>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
        <div
          v-if="openInputSave()"
          class="d-flex justify-end"
        >
          <v-row class="d-flex justify-end mt-3">
            <v-col
              cols="2"
              class="d-flex justify-space-between"
            >
              <v-btn
                large
                block
                color="info"
                :disabled="isActiveSave"
                @click="handleSubmit(handleSubmitForm)"
              >
                <span class="text-h5 pr-3 pl-3">
                  {{ $t('messages.signup') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="2"
              class="d-flex justify-space-between"
            >
              <v-btn
                large
                block
                color="info"
                @click="deleteJob"
              >
                <span class="text-h5 pr-3 pl-3">
                  {{ $t('button.delete') }}</span>
              </v-btn>
            </v-col>
            <v-col cols="2">
              <v-btn
                large
                block
                color="info"
                @click="$router.push({path: '/jobs'})"
              >
                <span class="text-h5 pr-3 pl-3">
                  {{ $t('messages.close') }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </validation-observer>
    <dialog-delete-shift
      :dialog-delete-shift="dialogDeleteShift"
      :index="indexShift"
      @removeShift="removeShift()"
      @closeDialogDelete="closeDialogDelete()"
    />
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
  import { get } from 'vuex-pathify'
  import _ from 'lodash'
  import { mask } from 'vue-the-mask'
  import { required } from 'vee-validate/dist/rules'
  import moment from 'moment'
  import DialogDeleteShift from '../../components/DialogDeleteShift'

  export default {
    directives: { mask },
    name: 'DetailJob',
    components: {
      ValidationProvider,
      ValidationObserver,
      DialogDeleteShift,
    },
    data () {
      return {
        dialog: false,
        dialogDeleteShift: false,
        searchJob: '',
        fromTime: '',
        toTime: '',
        shifts: [],
        shiftsShow: [],
        namejob: '',
        period: '',
        numOfWorker: '',
        workDay: '',
        hourlyPayment: '',
        description: '',
        shiftStartAt: '',
        shiftEndAt: '',
        planBreakTime: '',
        customerId: '',
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        includeShift: 1,
        jobs: [],
        isEdit: true,
        jobId: '',
        nameCompany: '',
        indexShift: 0,
        job: '',
        isShowBtnAdd: false,
        isEditInfo: true,
        isEditTimeShift: true,
        isSubmitted: false,
        statusSubmit: [
          {
            value: true,
            text: '普通',
          },
          {
            value: false,
            text: '夜勤',
          },
        ],
        isActiveSave: false,
      }
    },
    computed: {
      ...get('job', ['jobDetail', 'message', 'status', 'error']),
    },
    watch: {
      error (value) {
        if (value) {
          this.snackbarMessage = this.$t(value)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      shiftStartAt (data) {
        if (data.length === 1 && parseInt(data) > 4) {
          this.shiftStartAt = '0' + data + ':'
        } else if (data.length === 2) return (this.shiftStartAt = data + ':')
      },
      shiftEndAt (data) {
        if (data.length === 1 && parseInt(data) > 4) {
          this.shiftEndAt = '0' + data + ':'
        } else if (data.length === 2) return (this.shiftEndAt = data + ':')
      },
      jobDetail (value) {
        if (value) {
          this.job = value.data.job
          this.namejob = value.data.job.name ? value.data.job.name : ''
          this.period = value.data.job.period ? value.data.job.period : ''
          this.numOfWorker = value.data.job.numOfWorker ? value.data.job.numOfWorker : ''
          this.workDay = value.data.job.workDay ? value.data.job.workDay : ''
          this.description = value.data.job.description ? value.data.job.description : ''
          this.hourlyPayment = value.data.job.hourlyPayment ? value.data.job.hourlyPayment : ''
          this.customerId = value.data.job.customerId ? value.data.job.customerId : ''
          this.shifts = value.data.job.shifts ? value.data.job.shifts : []
        }
      },
      message (value) {
        if (value === 'updateSuccess') {
          this.showSnackBar('messages.updateSuccess')
          this.$router.push({
            path: '/jobs',
            query: { updateSuccess: true },
          })
        }
        if (value === 'deleteSuccess') {
          this.showSnackBar('messages.deleteSuccess')
          this.$router.push({
            path: '/jobs',
            query: { updateSuccess: true },
          })
        }
        if (value === 'success') {
          this.showSnackBar('messages.success')
        }
      },
      dialog (value) {
        if (!value) {
          this.reload()
        }
      },
      searchJob (value) {
        if (!value) {
          this.onSearch()
        }
      },
      isEditTimeShift (value) {
        if (!value) {
          this.isShowBtnAdd = true
        } else {
          this.isShowBtnAdd = false
        }
      },
      shifts (value) {
        this.shiftsShow = value.filter(shift => !shift.deleted)
      },
    },
    mounted () {
      this.jobId = this.$route.params.id
      extend('required', {
        ...required,
        message: this.$t('validator.required'),
      })
      extend('checktime', {
        events: ['blur'],
        validate (value) {
          if (/^[0,1,2,3,4]{1}[0-9]{1}:[0-5]{1}[0-9]{1}$/g.test(value)) {
            const timeSplited = value.split(':')
            if (timeSplited[0] === 24) return false
            return true
          }
          return false
        },
        message: this.$t('validator.invalidTimeFormat'),
      })
      extend('checktime24h', {
        events: ['blur'],
        validate (value) {
          if (!moment(value, 'HH:mm').isValid()) return false
          return true
        },
        message: this.$t('validator.invalidTimeFormat'),
      })
      extend('alwaysPositive', {
        events: ['change'],
        validate (value) {
          if (value < 0) return false
          return true
        },
        message: this.$t('validator.alwaysPositive'),
      })
      this.getJob()
    },
    methods: {
      handleCheckBreakTime (shift) {
        const firstShiftStartAt = parseInt(shift.shiftStartAt.slice(0, 2))
        const firstShiftEndAt = parseInt(shift.shiftEndAt.slice(0, 2))
        const lastShiftStartAt = parseInt(shift.shiftStartAt.slice(3, 5))
        const lasttShiftEndAt = parseInt(shift.shiftEndAt.slice(3, 5))
        const timeFirstPlanBreak = parseInt(shift.planBreakTime.slice(0, 2))
        const timeLastPlanBreak = parseInt(shift.planBreakTime.slice(3, 5))

        const totalBreakTime = timeFirstPlanBreak + (timeLastPlanBreak / 60)
        const totalTimeWork = (firstShiftEndAt - firstShiftStartAt) + ((lasttShiftEndAt - lastShiftStartAt) / 60)

        if (totalBreakTime >= totalTimeWork) {
          this.isActiveSave = true
          shift.planBreakTime = ''
          this.showSnackBar('messages.breakTimeExceeds')
        } else this.isActiveSave = false
      },
      getLableJobActive (isActive) {
        return isActive ? '稼働中' : '停止中'
      },
      openInputSave () {
        if (!this.isEditInfo || !this.isEditTimeShift || this.isShowBtnAdd) {
          return true
        }
        return false
      },
      getJob () {
        const payload = {
          id: this.jobId,
        }
        this.$store.dispatch('job/getJob', payload)
      },
      handleChangeStatusJob (isActive) {
        const payload = {
          jobId: this.jobId,
          isActive: isActive,
        }
        this.$store.dispatch('job/changeStatus', payload)
      },
      openDialogEdit (jobId) {
        this.isEdit = true
        const job = this.jobs.find((job) => job.id === jobId)
        if (job) {
          this.jobId = jobId
          this.customerId = job.customerId
          this.namejob = job.name
          this.period = job.period
          this.numOfWorker = job.numOfWorker
          this.workDay = job.workDay
          this.hourlyPayment = job.hourlyPayment
          this.description = job.description
          this.shifts = job.shifts
        }
        this.dialog = true
      },
      handleSubmitForm () {
        if (this.isEdit) {
          this.updateJob()
        } else {
          this.createJob()
        }
      },
      createJob: _.debounce(function () {
        if (this.shifts.length === 0) {
          this.showSnackBar('messages.pleaseEnterShift')
          return
        }
        const payload = {
          customerId: this.customerId,
          name: this.namejob,
          period: this.period,
          numOfWorker: this.numOfWorker,
          workDay: this.workDay,
          hourlyPayment: this.hourlyPayment,
          description: this.description,
          shifts: this.shifts,
        }

        this.$store.dispatch('job/createJob', payload)
        this.reload()
        this.dialog = false
        setTimeout(() => {
          this.getJob()
        }, 300)
      }, 500),
      updateJob () {
        if (this.shifts.length === 0) {
          this.showSnackBar('messages.pleaseEnterShift')
          return
        }
        if (!this.shifts.find(shift => !shift.deleted)) {
          this.showSnackBar('messages.pleaseEnterShift')
          return
        }
        const payload = {
          jobId: this.jobId,
          customerId: this.customerId,
          name: this.namejob,
          period: this.period,
          numOfWorker: this.numOfWorker,
          workDay: this.workDay,
          hourlyPayment: this.hourlyPayment,
          description: this.description,
          shifts: this.shifts,
        }

        this.$store.dispatch('job/updateJob', payload)
        this.isEditTimeShift = true
        this.isEditInfo = true
      },
      deleteJob () {
        const payload = {
          jobId: this.jobId,
        }
        this.$store.dispatch('job/deleteJob', payload)
      },
      handleShift () {
        this.isActiveSave = false
        let worktimeType = true
        if (!this.shiftStartAt) {
          this.shiftStartAt = this.shiftEndAt
          this.shiftEndAt = ''
        }
        if (this.shiftStartAt && this.shiftEndAt) {
          if (this.checkStartTimeEndTime(this.shiftStartAt, this.shiftEndAt)) {
            return
          }
          this.checkDuplicaTime(this.shiftStartAt, this.shiftEndAt)
          if (this.checkTime(this.shiftStartAt) || this.checkTime(this.shiftEndAt)) {
            return
          }
          const firstShiftStartAt = parseInt(this.shiftStartAt.slice(0, 2))
          const firstShiftEndAt = parseInt(this.shiftEndAt.slice(0, 2))

          if (firstShiftStartAt >= 0 && firstShiftEndAt <= 5) {
            worktimeType = false
          } else if (firstShiftStartAt >= 22 && firstShiftEndAt <= 29) {
            worktimeType = false
          } else if (firstShiftStartAt >= 46) {
            worktimeType = false
          }

          this.shifts.push({
            shiftStartAt: this.shiftStartAt,
            shiftEndAt: this.shiftEndAt,
            worktimeType: worktimeType,
            planBreakTime: this.planBreakTime,
          })
          this.shiftStartAt = ''
          this.shiftEndAt = ''
          this.planBreakTime = ''
        }
      },
      checkStartTimeEndTime (shiftStartAt, shiftEndAt) {
        const timeFirstStart = parseInt(shiftStartAt.slice(0, 2))
        const timeLastStart = parseInt(shiftStartAt.slice(3, 5))
        const timeFirstEnd = parseInt(shiftEndAt.slice(0, 2))
        const timeLastEnd = parseInt(shiftEndAt.slice(3, 5))
        if (this.checkActualAt(timeFirstStart, timeFirstEnd, timeLastStart, timeLastEnd)) {
          this.shiftStartAt = ''
          this.shiftEndAt = ''
          return true
        }
      },
      checkActualAt (timeFirstStart, timeFirstEnd, timeLastStart, timeLastEnd) {
        let error = false
        if (timeFirstStart > timeFirstEnd) {
          this.showSnackBar('出勤時間は退勤時間より前である必要があります。')
          error = true
        } else if (timeFirstStart === timeFirstEnd && timeLastStart > timeLastEnd) {
          this.showSnackBar('出勤時間は退勤時間より前である必要があります。')
          error = true
        }
        return error
      },
      checkWorkTimeType (shiftStartAt, shiftEndAt) {
        let isDisabled = false
        const firstShiftStartAt = parseInt(shiftStartAt.slice(0, 2))
        const firstShiftEndAt = parseInt(shiftEndAt.slice(0, 2))
        if (firstShiftStartAt >= 0 && firstShiftEndAt <= 5) {
          isDisabled = true
        } else if (firstShiftStartAt >= 5 && firstShiftEndAt <= 22) {
          isDisabled = true
        } else if (firstShiftStartAt >= 22 && firstShiftEndAt <= 29) {
          isDisabled = true
        } else if (firstShiftStartAt >= 29 && firstShiftEndAt <= 46) {
          isDisabled = true
        } else if (firstShiftStartAt >= 46) {
          isDisabled = true
        }
        return isDisabled
      },
      checkIsDisable (isEdit, isWorkTimeType) {
        let isDisable = false
        if (isWorkTimeType) {
          isDisable = true
        } else {
          if (!isEdit) {
            isDisable = false
          } else if (!this.isShowBtnAdd) {
            isDisable = true
          }
        }
        return isDisable
      },
      checkTime (time) {
        if (!(/^[0,1,2,3,4]{1}[0-9]{1}:[0-5]{1}[0-9]{1}$/g.test(time))) {
          return true
        }
        return false
      },
      checkTime24h (time) {
        if (!moment(time, 'HH:mm').isValid()) {
          return true
        }
        return false
      },
      checkDuplicaTime (shiftStartAt, shiftEndAt) {
        if (this.shifts.length !== 0) {
          this.shifts.forEach(shift => {
            if (shiftStartAt === shift.shiftStartAt && shiftEndAt === shift.shiftEndAt) {
              this.errorDuplication()
              return true
            }
          })
        }
      },
      checkDuplicaTimeStart (time) {
        if (time && this.shifts.length !== 0) {
          this.shifts.forEach(shift => {
            if (time === shift.shiftStartAt) {
              this.errorDuplication()
              return true
            }
            if (
              moment(time, 'HH:mm').isAfter(moment(shift.shiftStartAt, 'HH:mm')) &&
              moment(time, 'HH:mm').isBefore(moment(shift.shiftEndAt, 'HH:mm'))
            ) {
              this.errorDuplication()
              return true
            }
            if (
              moment(shift.shiftEndAt, 'HH:mm').isBefore(moment(shift.shiftStartAt, 'HH:mm')) &&
              (
                moment(time, 'HH:mm').isAfter(moment(shift.shiftStartAt, 'HH:mm')) ||
                moment(time, 'HH:mm').isBefore(moment(shift.shiftEndAt, 'HH:mm'))
              )
            ) {
              this.errorDuplication()
              return true
            }
          })
        }
      },
      checkDuplicaTimeEnd (timeEnd, timeStart) {
        if (timeEnd && this.shifts.length !== 0) {
          this.shifts.forEach(shift => {
            if (moment(shift.shiftEndAt, 'HH:mm').isBefore(moment(shift.shiftStartAt, 'HH:mm'))) {
              if (
                moment(timeEnd, 'HH:mm').isAfter(moment(shift.shiftStartAt, 'HH:mm')) ||
                moment(timeEnd, 'HH:mm').isBefore(moment(shift.shiftEndAt, 'HH:mm'))
              ) {
                this.errorDuplication()
                return true
              }

              if (
                moment(timeEnd, 'HH:mm').isBefore(moment(timeStart, 'HH:mm'))
              ) {
                this.errorDuplication()
                return true
              }
            } else {
              if (
                moment(timeEnd, 'HH:mm').isAfter(moment(shift.shiftStartAt, 'HH:mm')) &&
                moment(timeEnd, 'HH:mm').isBefore(moment(shift.shiftEndAt, 'HH:mm'))
              ) {
                this.errorDuplication()
                return true
              }
              if (
                moment(timeStart, 'HH:mm').isBefore(moment(shift.shiftStartAt, 'HH:mm')) &&
                moment(timeEnd, 'HH:mm').isAfter(moment(shift.shiftStartAt, 'HH:mm'))
              ) {
                this.errorDuplication()
                return true
              }
            }
          })
        }
      },
      errorDuplication () {
        this.shiftStartAt = ''
        this.shiftEndAt = ''
        this.showSnackBar('messages.duplicationTime')
      },
      openDialogDeleteShift (index) {
        this.indexShift = index
        if (this.isEdit) {
          this.dialogDeleteShift = true
        } else {
          this.removeShift()
        }
      },
      removeShift () {
        if (this.isEdit) {
          this.dialogDeleteShift = true
          const arr = this.shifts
          arr[this.indexShift].deleted = true
          this.shifts = []
          this.shifts = arr
        } else {
          this.shifts.splice(this.indexShift, 1)
        }
      },
      reload () {
        this.namejob = ''
        this.period = ''
        this.numOfWorker = ''
        this.workDay = ''
        this.hourlyPayment = ''
        this.description = ''
        this.shiftStartAt = ''
        this.shiftEndAt = ''
        this.shifts = []
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      closeDialogDelete () {
        this.dialogDeleteShift = false
      },
    },
  }
</script>

<style scoped lang="sass">
.v-dialog
  div
    background-color: #daf6ff
.card-active
  background-color: #daf6ff
.cart-deactive
  background-color: #ccd7e0
.cart-item-style
  padding: 0 12px
  border-collapse: collapse
  border-color: currentColor
  border-style: solid
  border-width: 1px
  min-height: 40px
  border-radius: 5px
  color: rgba(0, 0, 0, 0.26)
.header-job
  background-color: #b6edff
  border-radius: 20px
  font-size: 16px
.btn-search-job
  width: 40%
.style-card
  max-height: 410px
  overflow: auto
.style-card::-webkit-scrollbar
  width: 10px
.style-card::-webkit-scrollbar-track
  background: #d9dbe2
  border-radius: 8px
.style-card::-webkit-scrollbar-thumb
  background: #bec3cd
  border-radius: 8px
.v-card__text::-webkit-scrollbar
  width: 10px
.v-card__text::-webkit-scrollbar-track
  background: #d9dbe2
  border-radius: 8px
.v-card__text::-webkit-scrollbar-thumb
  background: #bec3cd
  border-radius: 8px
.job-time
  background-color: #e2edfc
  border-radius: 5px
.custome-switch
  margin-top: 2px
.custome-shift
  line-height: 2.5rem
.inputMask
  border: 1px solid #c5c3c3
  border-radius: 3px
  width: 80%
  height: 60%
  text-align: center
</style>
